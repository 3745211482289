import Analytics from 'components/Analytics'
import SEO from 'components/SEO'
import { graphql } from 'gatsby'
import { renderContentBlocks } from 'lib/renderContentBlocks'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { productsActions } from 'store/products/slice'

const CollectionTemplate = ({
  data: { collection, allSanityProduct },
  pageContext: { pageUrl },
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(productsActions.setProducts(allSanityProduct.nodes))
  }, [])

  return (
    <div>
      <SEO
        defaultMeta={{ metaTitle: collection.title }}
        pageMeta={collection.meta}
        pagePath={pageUrl}
      />
      <Analytics {...collection.analytics} />
      {renderContentBlocks(collection.contentBlocks)}
    </div>
  )
}

export default CollectionTemplate

CollectionTemplate.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.shape({
      meta: PropTypes.object,
      analytics: PropTypes.object,
      title: PropTypes.string.isRequired,
      contentBlocks: PropTypes.arrayOf(
        PropTypes.shape({
          _key: PropTypes.string.isRequired,
          _type: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    pageUrl: PropTypes.string.isRequired,
  }).isRequired,
}

export const query = graphql`
  query CollectionQuery($id: String!) {
    collection: sanityCollection(id: { eq: $id }) {
      meta {
        ...MetaCard
      }
      analytics {
        ...AnalyticsCard
      }
      title
      contentBlocks {
        ...Blocks
      }
    }
    allSanityProduct {
      nodes {
        ...Product
      }
    }
  }
`
